import React from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import {Helmet} from "react-helmet/es/Helmet";

export default () => (
    <Layout>
        <Helmet>
            <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
            <meta name="description"
                  content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
            <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
            <meta name="description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:site_name" content="Cueblocks"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
            <meta property="og:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta property="og:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta name="twitter:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta name="twitter:site" content="@cueblocks"/>
            <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <div className="cb_expertiseInner">
            <div className="services-inner services-custom" >
                <h1>Marketplace Listing</h1>

                <p>Markeplaces help retailers to reach out to a larger audience and hence increase the revenue.</p>

                <p>Our team of internet marketers have an in-depth knowledge of the ins and outs of some of the most popular
                    marketplaces and can certainly help you in generating a good ROI.</p>

                <h2>Our Methodology:</h2>

                <ul>
                    <li>Account Set Up - No strategic marketplace management is possible unless you are on them. We’ll take care of your
                    Account set up and begin our ‘conquer the marketplace mission’ from there on.</li>

                    <li>Data Feed Management - A good beginning is job half done. We follow the account setup with active optimization of
                    data feeds to suit the unique requirements of different shopping engines. We are experienced in managing attributes
                    and feeds by working on strategies that can best utilize the marketplace potential for eCommerce stores.</li>

                    <li>Categorization - As marketplace success is dependent on efficient account management, we ensure that our clients
                    have their products perfectly categorized into relevant categories. Cost/ Conversion rates vary for each category
                    and wrong categorization may lead to poor results. Our product categorization is unique for each marketplace.</li>

                    <li>Keyword Research - Our extensive keyword research makes marketplace optimization even more result-oriented and
                    effective.</li>

                    <li>Optimization on Marketplaces - Our marketplace experts optimize title, description and categories using the
                    keyword research to improve rankings.</li>
                </ul>

                <h2>We manage the following Marketplaces:</h2>

                <ul>
                    <li>Amazon</li>
                    <li>Ebay</li>
                </ul>
                <div className="get-touch">
                    <p>like what you see?</p>
                    <a href="/contact" className="main_cta">Get in touch</a>
                </div>
                <div className="get-touch learn-casestudy">
                    <h3>LEARN HOW WE HELPED ONE OF OUR CLIENTS OPTIMIZE AND LEVERAGE THEIR AMAZON MARKETPLACE LISTING</h3>
                    <a href="marketplaces/case-study/amazon-marketplace.html" className="main_cta">Case Study</a>
                    <a href="/dummypath" className="main_cta">dummy</a>
                </div>
            </div>
        </div>
        <Clutch />

    </Layout>

)